import LineGradient from '../components/LineGradient'
import { motion } from 'framer-motion'

const MyProjects = () => {
    return (
        <section id="projects" className="pt-48 pb-48">
            {/* Headings */}
            <div className="md:flex md:justify-between md:gap-16">
                <motion.div
                    className="md:w-1/3"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 },
                    }}
                >
                    <p className="font-playfair font-semibold text-4xl mb-5">
                        MY <span className="text-red">PROJECTS</span>
                    </p>
                    <LineGradient width="w-1/2" />
                    <p className="mt-10">
                        These are some of my personal projects I've been working
                        on.
                    </p>
                </motion.div>
            </div>

            {/* Projects section */}
            <div className="md:flex md:justify-between mt-16 gap-32">
                {/* RevGrad */}
                <motion.div
                    className="md:w-1/2 mt-10"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className="relative h-20">
                        <div className="z-10 flex items-center">
                            <p className="font-playfair font-semibold text-3xl">
                                RevGrad
                            </p>
                            <a
                                className="ml-5"
                                href="https://github.com/licorice-vector/RevGrad"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    alt="github-link"
                                    src="../assets/github.png"
                                />
                            </a>
                        </div>
                    </div>
                    <p>
                        A small deep learning (reverse automatic
                        differentiation) library.
                    </p>
                </motion.div>

                {/* GammonDRL */}
                <motion.div
                    className="md:w-1/2 mt-10"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className="relative h-20">
                        <div className="z-10 flex items-center">
                            <p className="font-playfair font-semibold text-3xl">
                                GammonDRL
                            </p>
                            <a
                                className="ml-5"
                                href="https://github.com/licorice-vector/GammonDRL"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    alt="github-link"
                                    src="../assets/github.png"
                                />
                            </a>
                        </div>
                    </div>
                    <p>
                        A backgammon AI built using deep reinforcement learning.
                    </p>
                </motion.div>
            </div>
        </section>
    )
}

export default MyProjects
