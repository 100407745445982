import SocialMediaIcons from '../components/SocialMediaIcons'

const Footer = () => {
    return (
        <footer className="bg-red pt-10 pb-10">
            <div className="w-5/6 mx-auto">
                <SocialMediaIcons />
                <div className="md:flex justify-center md:justify-between text-center mt-6">
                    <p className="font-playfair font-semibold text-2xl text-yellow">
                        Christian Steensbjerg Munk Jensen
                    </p>
                    <p className="font-playfair text-md text-yellow mt-2 md:mt-0">
                        Copyright © 2024 Christian S. Munk Jensen. All rights
                        reserved.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
