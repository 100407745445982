import Footer from './scenes/Footer'
import Contact from './scenes/Contact'
import MyProjects from './scenes/MyProjects'
import MyAwards from './scenes/MyAwards'
import LineGradient from './components/LineGradient'
import Navbar from './scenes/Navbar'
import DotGroup from './scenes/DotGroup'
import Landing from './scenes/Landing'
import { useState, useEffect } from 'react'
import useMediaQuery from './hooks/useMediaQuery'

function App() {
    const [selectedPage, setSelectedPage] = useState('home')
    const [isTopOfPage, setIsTopOfPage] = useState(true)
    const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)')

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) setIsTopOfPage(true)
            if (window.scrollY !== 0) setIsTopOfPage(false)
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <div className="app bg-deep-blue">
            <Navbar
                isTopOfPage={isTopOfPage}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
            />
            <div className="w-5/6 mx-auto md:h-full">
                {isAboveMediumScreens && (
                    <DotGroup
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                )}
                <Landing setSelectedPage={setSelectedPage} />
            </div>
            <LineGradient />
            <div className="w-5/6 mx-auto">
                <MyAwards />
            </div>
            <LineGradient />
            <div className="w-5/6 mx-auto">
                <MyProjects />
            </div>
            <LineGradient />
            <div className="w-5/6 mx-auto">
                <Contact />
            </div>
            <Footer />
        </div>
    )
}

export default App
